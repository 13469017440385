<template>
  <q-modal :active="modalActive" :show-close-button="false" :fullHeight="true">
    <q-spacer bottom="md">
      <q-text variant="ui-baseline" ink="label" bold center>{{ modalContent.title }}</q-text>
    </q-spacer>
    <q-spacer bottom="lg">
      <q-text variant="ui-large" ink="muted" center>{{ modalContent.message }}</q-text>
    </q-spacer>
    <q-button variant="primary" @click="hideModal()">{{ $t('okButtonLabel') }}</q-button>
  </q-modal>
</template>

<script>
import { Modal as QModal, Text as QText, Spacer as QSpacer, Button as QButton } from '@qoala/ui';

export default {
  name: 'ToolMessage',
  components: {
    QModal,
    QText,
    QButton,
    QSpacer
  },
  computed: {
    modalActive() {
      return this.$store.getters['general/getMessageStatus'];
    },
    modalContent() {
      return this.$store.getters['general/getModalMessage'];
    }
  },
  methods: {
    hideModal() {
      this.$store.dispatch('general/setModalMessage', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.message__desc {
  white-space: pre-wrap;
}
</style>
